<template>
	<div class="register" @click="closeRegister">
		<div class="pagbox">

		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				type: 'phone',
			}
		},
		// props: {
		// 	message: {
		// 		type: String, // 数据类型
		// 		required: true, // 是否必须
		// 	}
		// },
		props: {
			message: String
		},
		mounted() {
			this.loginType()
			document.title = '注册-众享数字有限公司';
		},
		methods: {
			closeRegister(e) {
				if (e.target.getAttribute('class') == 'register') {
					console.log('进来了')
					this.$emit('close')
				}
			},
		}
	}
</script>

<style lang="scss">
	.register {
		width: 100%;
		height: 900px;
		background-color: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0px;
		z-index: 5000;

		.pagbox {
			width: 530px;
			height: 500px;
			position: absolute;
			top: 50%;
			left: 50%;
			background-color: #fff;
			transform: translate(-50%, -50%);
			border-radius: 2px;
		}
	}
</style>